<template>
  <div id="user-profile">
    <user-header />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <prayer-nav />
      </div>
    </b-card>
    <b-row class="mt-2">
      <b-col
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-text>
            <h4>Import Prayers</h4>
          </b-card-text>

          <b-col cols="12">
            <b-form-group
              label="Import File"
              label-for="file"
            >
              <b-form-file
                id="file"
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              size="md"
              variant="primary"
            >
              Import
            </b-button>
          </b-col>
        </b-card>
      </b-col>
      <b-col
        md="6"
        lg="6"
      >
        <b-card>
          <b-card-text>
            <h4>Export Prayers</h4>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BCardText, BFormFile,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import UserHeader from './UserHeader.vue'
import prayerNav from './Nav.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BFormGroup,
    UserHeader,
    prayerNav,
    BCardText,
  },
  data() {
    return {
      cityData: {
        city: '',
        country: '',
        from: '',
        to: '',
        timezone: '',
        calculation_method: '',
        madhab_method: '',
        high_latitude_rule: '',
        polar_circle_resolution: '',
        fajr_angle: '',
        isha_angle: '',
        adjustments: '',
        lat: '',
        long: '',
      },
    }
  },
}
</script>

<style lang="scss" >

@import '@core/scss/vue/pages/page-profile.scss';

</style>
