<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <div class="justify-content-center text-center btn-prayers">
        <b-button
          variant="primary"
          class="mr-5 p-2 pr-4 pl-4"
          :class="{active: $route.name === 'prayers-dash'}"
          :to="{ name: 'prayers-dash'}"
        >
          <span class="text-nowrap">All Prayers</span>
        </b-button>
        <b-button
          variant="primary"
          class="mr-5 p-2 pr-4 pl-4"
          :class="{active: $route.name === 'update-iqama'}"
          :to="{ name: 'update-iqama'}"
        >
          <span class="text-nowrap">Update Iqama</span>
        </b-button>
        <b-button
          variant="primary"
          class="mr-5 p-2 pr-4 pl-4"
          :class="{active: $route.name === 'update-azan'}"
          :to="{ name: 'update-azan'}"
        >
          <span class="text-nowrap">Get Azan</span>
        </b-button>
        <b-button
          variant="primary"
          class="mr-5 pt-2 pb-2 pr-4 pl-4"
          :class="{active: $route.name === 'import-export'}"
          :to="{ name: 'import-export'}"
        >
          <span class="text-nowrap">Import | Export</span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
}
</script>

<style>

</style>
